.ActionButtonComponent {
  border: 1px solid var(--border);
  padding: var(--sp_lv_1) var(--sp_lv_2);
  border-radius: var(--br_DEFAULT);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover {
    background-color: var(--gray_2);
  }
  &.statistic,
  &.option {
    min-width: 32px;
    min-height: 32px;
    img {
      width: 16px;
      height: 16px;
    }
  }
}
